import 'script-loader!./vendor/jquery.js'
import 'script-loader!./vendor/jquery-rebox.js'
require('script-loader!popper.js/dist/umd/popper.min.js')
require('script-loader!underscore/underscore-min.js')
require('script-loader!backbone/backbone-min.js')
require('script-loader!glider-js/glider.min.js')
import 'script-loader!./vendor/bootstrap/collapse.js'
import 'script-loader!./vendor/bootstrap/carousel.js'
import 'script-loader!./vendor/bootstrap/dropdown.js'
import 'script-loader!./vendor/bootstrap/scrollspy.js'

import Vue from './vendor/vue.js'
import VueRouter from './vendor/vue-router.js'

Vue.use(VueRouter)

window.Vue = Vue
window.VueRouter = VueRouter
